import React from "react";
import { Helmet } from "react-helmet";

import MainLayout from "../../components/MainLayout";

const BlogEntry: React.FC = () => (
  <MainLayout>
    <Helmet>
      <title>Jörg.dev | Code Editor for Github</title>
    </Helmet>
    <section>
      <h1>Code Editor for Github</h1>
      <p>
        Did you ever get in the situation where you wanted to browse the source
        code of a repository on Github without cloning the project locally?
      </p>
      <p>
        There is a very nice project called{" "}
        <a href="https://github1s.com/">github1s</a> that gives you an almost
        instant ("1s") visual studio code like editor to any (public)
        repositiory on Github.
      </p>
    </section>
  </MainLayout>
);

export default BlogEntry;
